<template>
  <div class="lg:px-60 px-10 py-10 font-montserrat ">
    <h2 class="text-center">PRIVACY NOTICE</h2>
    <p>
      This privacy notice for OONPAY INC (doing business as Oonpay Technologies
      ) (
      <b class="text-black ">"Oonpay Technologies ," "we," "us," or "our"</b> ),
      describes how and why we might collect, store, use, and/or share
      ("process") your information when you use our services ("Services"), such
      as when you:
    </p>

    <ul class="list-square px-5">
      <li>
        Visit our website at http://oonpay.com, or any website of ours that
        links to this privacy notice
      </li>
      <li>
        Download and use our mobile application, or any other application of
        ours that links to this privacy notice
      </li>
      <li>
        Engage with us in other related ways, including any sales, marketing, or
        events
      </li>
    </ul>

    <p>
      <b class="text-black "> Questions or concerns? </b> Reading this privacy
      notice will help you understand your privacy rights and choices. If you do
      not agree with our policies and practices, please do not use our Services.
      If you still have any questions or concerns, please contact us at
      info@oonapy.com.
    </p>

    <h4>SUMMARY OF KEY POINTS</h4>
    <p>
      <b class="text-black">
        <i
          >This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for. You can also click
          <a href="#">here</a> to go directly to our table of contents.</i
        >
      </b>
    </p>

    <p>
      What personal information do we process? When you visit, use, or navigate
      our Services, we may process personal information depending on how you
      interact with Oonpay Technologies and the Services, the choices you make,
      and the products and features you use. Click <a href="#">here</a> to learn
      more.
    </p>

    <p>
      <b class="text-black"
        >Do we process any sensitive personal information?</b
      >
      We do not process sensitive personal information.
    </p>

    <p>
      <b class="text-black"
        >Do we receive any information from third parties?
      </b>
      We do not receive any information from third parties.
    </p>
    <p>
      <b class="text-black">How do we process your information? </b>
      We process your information to provide, improve, and administer our
      Services, communicate with you, for security and fraud prevention, and to
      comply with law. We may also process your information for other purposes
      with your consent. We process your information only when we have a valid
      legal reason to do so. Click <a href="#"> here</a> to learn more.
    </p>

    <p>
      <b class="text-black"
        >In what situations and with which parties do we share personal
        information?</b
      >
      We may share information in specific situations and with specific third
      parties. Click here to learn more.
    </p>

    <p>
      <b class="text-black">How do we keep your information safe? </b>We have
      organizational and technical processes and procedures in place to protect
      your personal information. However, no electronic transmission over the
      internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      <a href="#">Click</a>
      here to learn more.
    </p>

    <p>
      <b class="text-black">What are your rights?</b> Depending on where you are
      located geographically, the applicable privacy law may mean you have
      certain rights regarding your personal information. Click here to learn
      more.
    </p>

    <p>
      <b class="text-black">How do you exercise your rights?</b> The easiest way
      to exercise your rights is by filling out our data subject request form
      available here, or by contacting us. We will consider and act upon any
      request in accordance with applicable data protection laws.
    </p>

    <p>
      Want to learn more about what Oonpay Technologies does with any
      information we collect? Click here to review the notice in full.
    </p>

    <h4 class="py-3">TABLE OF CONTENTS</h4>
    <ul class="list-decimal px-5 u">
      <li><a href="#">WHAT INFORMATION DO WE COLLECT ?</a></li>
      <li><a href="#">HOW DO WE PROCESS YOUR INFORMATION ?</a></li>
      <li>
        <a href="#"
          >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION
          ?</a
        >
      </li>
      <li>
        <a href="#">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES ?</a>
      </li>
      <li><a href="#"> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
      <li><a href="#">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
      <li><a href="#">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
      <li><a href="#">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
      <li><a href="#">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
      <li>
        <a href="#">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
      </li>
      <li>
        <a href="#">DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
      </li>
      <li><a href="#">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
      <li><a href="#">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
      <li>
        <a href="#"
          >HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?</a
        >
      </li>
    </ul>

    <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
    <p class="text-black text-lg">Personal information you disclose to us</p>
    <p class="">
      <span class="text-black">In Short: </span> We collect personal information
      that you provide to us.
    </p>
    <p>
      <span class="text-black">Personal Information Provided by You.</span> The
      personal information that we collect depends on the context of your
      interactions with us and the Services, the choices you make, and the
      products and features you use. The personal information we collect may
      include the following:
    </p>

    <ul class="list-square px-5">
      <li>Names</li>
      <li>Phone numbers</li>
      <li>email address</li>
      <li>mailing addresses</li>
      <li>usernames</li>
      <li>contact preferences</li>
      <li>contact or authentication data</li>
      <li>billing addresses</li>
      <li>debit/credit card numbers</li>
      <li>passwords</li>
      <li>job titles</li>
    </ul>

    <p>
      <span class="text-black">Sensitive Information.</span> We do not process
      sensitive information.
    </p>

    <p>
      <span class="text-black">Payment Data.</span> We may collect data
      necessary to process your payment if you make purchases, such as your
      payment instrument number, and the security code associated with your
      payment instrument. All payment data is stored by
      <a href="#">https://stripe.com/</a>. You may find their privacy notice
      link(s) here: <a href="#">https://stripe.com/privacy</a>.
    </p>

    <p>
      <span class="text-black">Social Media Login Data.</span> We may provide
      you with the option to register with us using your existing social media
      account details, like your Facebook, Twitter, or other social media
      account. If you choose to register in this way, we will collect the
      information described in the section called
      <a href="#">"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below.
    </p>

    <p>
      <span class="text-black"> Application Data.</span> If you use our
      application(s), we also may collect the following information if you
      choose to provide us with access or permission:
    </p>

    <ul class="list-square px-5">
      <li>
        Geolocation Information. We may request access or permission to track
        location-based information from your mobile device, either continuously
        or while you are using our mobile application(s), to provide certain
        location-based services. If you wish to change our access or
        permissions, you may do so in your device's settings.
      </li>
      <li>
        Mobile Device Access. We may request access or permission to certain
        features from your mobile device, including your mobile device's camera,
        contacts, sms messages, calendar, sensors, and other features. If you
        wish to change our access or permissions, you may do so in your device's
        settings.
      </li>
      <li>
        Mobile Device Data. We automatically collect device information (such as
        your mobile device ID, model, and manufacturer), operating system,
        version information and system configuration information, device and
        application identification numbers, browser type and version, hardware
        model Internet service provider and/or mobile carrier, and Internet
        Protocol (IP) address (or proxy server). If you are using our
        application(s), we may also collect information about the phone network
        associated with your mobile device, your mobile device’s operating
        system or platform, the type of mobile device you use, your mobile
        device’s unique device ID, and information about the features of our
        application(s) you accessed.
      </li>
      <li>
        Push Notifications. We may request to send you push notifications
        regarding your account or certain features of the application(s). If you
        wish to opt out from receiving these types of communications, you may
        turn them off in your device's settings.
      </li>
    </ul>
    <p>
      This information is primarily needed to maintain the security and
      operation of our application(s), for troubleshooting, and for our internal
      analytics and reporting purposes.
    </p>
    <p>
      This information is primarily needed to maintain the security and
      operation of our application(s), for troubleshooting, and for our internal
      analytics and reporting purposes.
    </p>

    <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
    <p>
      <span class="text-black">In Short:</span> We process your information to
      provide, improve, and administer our Services, communicate with you, for
      security and fraud prevention, and to comply with law. We may also process
      your information for other purposes with your consent.
    </p>

    <p class="text-black">
      We process your personal information for a variety of reasons, depending
      on how you interact with our Services, including:
    </p>

    <ul class="list-square px-5">
      <li>
        <span class="text-black"
          >To facilitate account creation and authentication and otherwise
          manage user accounts.</span
        >
        We may process your information so you can create and log in to your
        account, as well as keep your account in working order.
      </li>
      <li>
        <span class="text-black"
          >To deliver and facilitate delivery of services to the user.</span
        >
        We may process your information to provide you with the requested
        service.
      </li>
      <li>
        <span class="text-black"
          >To respond to user inquiries/offer support to users.</span
        >
        We may process your information to respond to your inquiries and solve
        any potential issues you might have with the requested service.
      </li>
      <li>
        <span class="text-black"
          >To send administrative information to you.</span
        >
        We may process your information to send you details about our products
        and services, changes to our terms and policies, and other similar
        information.
      </li>
      <li>
        <span class="text-black">To fulfill and manage your orders.</span> We
        may process your information to fulfill and manage your orders,
        payments, returns, and exchanges made through the Services.
      </li>
      <li>
        <span class="text-black">To enable user-to-user communications.</span>
        We may process your information if you choose to use any of our
        offerings that allow for communication with another user.
      </li>
      <li>
        <span class="text-black">To request feedback.</span> We may process your
        information when necessary to request feedback and to contact you about
        your use of our Services.
      </li>
      <li>
        <span class="text-black">
          To send you marketing and promotional communications.</span
        >
        We may process the personal information you send to us for our marketing
        purposes, if this is in accordance with your marketing preferences. You
        can opt out of our marketing emails at any time. For more information,
        see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
      </li>
      <li>
        <span class="text-black">To deliver targeted advertising to you.</span>
        We may process your information to develop and display personalized
        content and advertising tailored to your interests, location, and more.
      </li>
      <li>
        <span class="text-black">To identify usage trends.</span> We may process
        information about how you use our Services to better understand how they
        are being used so we can improve them
      </li>
      <li>
        <span class="text-black">To identify usage trends.</span> We may process
        information about how you use our Services to better understand how they
        are being used so we can improve them
      </li>
    </ul>

    <h4>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>
    <p>
      <span class="text-black">In Short:</span> We only process your personal
      information when we believe it is necessary and we have a valid legal
      reason (i.e., legal basis) to do so under applicable law, like with your
      consent, to comply with laws, to provide you with services to enter into
      or fulfill our contractual obligations, to protect your rights, or to
      fulfill our legitimate business interests.
    </p>

    <a href="#"
      >If you are located in the EU or UK, this section applies to you.</a
    >
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to
      explain the valid legal bases we rely on in order to process your personal
      information. As such, we may rely on the following legal bases to process
      your personal information:
    </p>
    <ul class="list-square px-5">
      <li>
        <span class="text-black">Consent.</span> We may process your information
        if you have given us permission (i.e., consent) to use your personal
        information for a specific purpose. You can withdraw your consent at any
        time. Click here to learn more.
      </li>
      <li>
        <span class="text-black">Performance of a Contract.</span> We may
        process your personal information when we believe it is necessary to
        fulfill our contractual obligations to you, including providing our
        Services or at your request prior to entering into a contract with you.
      </li>
      <li>
        <span class="text-black">Legitimate Interests.</span> We may process
        your information when we believe it is reasonably necessary to achieve
        our legitimate business interests and those interests do not outweigh
        your interests and fundamental rights and freedoms. For example, we may
        process your personal information for some of the purposes described in
        order to:
        <ul class="list-disc px-5">
          <li>
            Send users information about special offers and discounts on our
            products and services
          </li>
          <li>
            Develop and display personalized and relevant advertising content
            for our users
          </li>
          <li>
            Analyze how our Services are used so we can improve them to engage
            and retain users
          </li>
          <li>
            Understand how our users use our products and services so we can
            improve user experience
          </li>
        </ul>
      </li>
      <li>
        <span class="text-black">Legal Obligations.</span> We may process your
        information where we believe it is necessary for compliance with our
        legal obligations, such as to cooperate with a law enforcement body or
        regulatory agency, exercise or defend our legal rights, or disclose your
        information as evidence in litigation in which we are involved.
      </li>
      <li>
        <span class="text-black">Vital Interests.</span> We may process your
        information where we believe it is necessary to protect your vital
        interests or the vital interests of a third party, such as situations
        involving potential threats to the safety of any person.
      </li>
    </ul>
    <a href="#">If you are located in Canada, this section applies to you.</a>
    <p>
      We may process your information if you have given us specific permission
      (i.e., express consent) to use your personal information for a specific
      purpose, or in situations where your permission can be inferred (i.e.,
      implied consent). You can withdraw your consent at any time. Click here to
      learn more.
    </p>
    <p>
      In some exceptional cases, we may be legally permitted under applicable
      law to process your information without your consent, including, for
      example
    </p>
    <ul class="list-square px-5">
      <li>
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way
      </li>
      <li>For investigations and fraud detection and prevention</li>
      <li>For business transactions provided certain conditions are met</li>
      <li>
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim
      </li>
      <li>
        For identifying injured, ill, or deceased persons and communicating with
        next of kin
      </li>
      <li>
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse
      </li>
      <li>
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a province
      </li>
      <li>
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records
      </li>
      <li>
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced
      </li>
      <li>
        If the collection is solely for journalistic, artistic, or literary
        purposes
      </li>
      <li>
        f the information is publicly available and is specified by the
        regulations
      </li>
    </ul>

    <h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
    <p>
      <span class="text-black">In Short:</span> We may share information in
      specific situations described in this section and/or with the following
      third parties.
    </p>
    <p>
      We may need to share your personal information in the following
      situations:
    </p>
    <ul class="list-square px-5">
      <li>
        <span class="text-black">Business Transfers.</span> We may share or
        transfer your information in connection with, or during negotiations of,
        any merger, sale of company assets, financing, or acquisition of all or
        a portion of our business to another company.
      </li>
      <li>
        <span class="text-black">When we use Google Maps Platform APIs.</span>
        We may share your information with certain Google Maps Platform APIs
        (e.g., Google Maps API, Places API). To find out more about Google’s
        Privacy Policy, please refer to this link. We use certain Google Maps
        Platform APIs to retrieve certain information when you make
        location-specific requests. This includes: User address to deliver
        ordered that are placed ; and other similar information. A full list of
        what we use information for can be found in this section and in the
        previous section titled "HOW DO WE PROCESS YOUR INFORMATION?". We obtain
        and store on your device ("cache") your location. You may revoke your
        consent anytime by contacting us at the contact details provided at the
        end of this document. The Google Maps Platform APIs that we use store
        and access cookies and other information on your devices. If you are a
        user currently in the European Economic Area (EU countries, Iceland,
        Liechtenstein, and Norway) or the United Kingdom, please take a look at
        our Cookie Notice.
      </li>
      <li>
        <span class="text-black">Business Partners.</span> We may share your
        information with our business partners to offer you certain products,
        services, or promotions.
      </li>
    </ul>

    <h4>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
    <p>
      <span class="text-black">In Short:</span> We may use cookies and other
      tracking technologies to collect and store your information.
    </p>
    <p>
      We may use cookies and similar tracking technologies (like web beacons and
      pixels) to access or store information. Specific information about how we
      use such technologies and how you can refuse certain cookies is set out in
      our Cookie Notice.
    </p>

    <h4>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
    <p>
      <span class="text-black">In Short:</span> If you choose to register or log
      in to our Services using a social media account, we may have access to
      certain information about you.
    </p>
    <p>
      Our Services offer you the ability to register and log in using your
      third-party social media account details (like your Facebook or Twitter
      logins). Where you choose to do this, we will receive certain profile
      information about you from your social media provider. The profile
      information we receive may vary depending on the social media provider
      concerned, but will often include your name, email address, friends list,
      and profile picture, as well as other information you choose to make
      public on such a social media platform.
    </p>
    <p>
      We will use the information we receive only for the purposes that are
      described in this privacy notice or that are otherwise made clear to you
      on the relevant Services. Please note that we do not control, and are not
      responsible for, other uses of your personal information by your
      third-party social media provider. We recommend that you review their
      privacy notice to understand how they collect, use, and share your
      personal information, and how you can set your privacy preferences on
      their sites and apps.
    </p>

    <h4>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
    <p>
      <span class="text-black">In Short:</span> We keep your information for as
      long as necessary to fulfill the purposes outlined in this privacy notice
      unless otherwise required by law.
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements). No purpose in this notice will require us keeping
      your personal information for longer than the period of time in which
      users have an account with us.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize such information, or, if
      this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal
      information and isolate it from any further processing until deletion is
      possible.
    </p>
    <h4>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
    <p>
      <span class="text-black">In Short:</span> We aim to protect your personal
      information through a system of organizational and technical security
      measures.
    </p>
    <p>
      We have implemented appropriate and reasonable technical and
      organizational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and
      efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information,
      transmission of personal information to and from our Services is at your
      own risk. You should only access the Services within a secure environment.
    </p>

    <h4>9. DO WE COLLECT INFORMATION FROM MINORS?</h4>
    <p>
      <span class="text-black">In Short:</span> We do not knowingly collect data
      from or market to children under 18 years of age.
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      info@oonpay.com.
    </p>
    <h4>10. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
    <p>
      In Short: In some regions, such as the European Economic Area (EEA),
      United Kingdom (UK), and Canada, you have rights that allow you greater
      access to and control over your personal information. You may review,
      change, or terminate your account at any time.
    </p>

    <p>
      In some regions (like the EEA, UK, and Canada), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section "HOW
      CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
    </p>

    <p>
      We will consider and act upon any request in accordance with applicable
      data protection laws.
    </p>
    <p>
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your local data protection supervisory authority. You can find their
      contact details here:
      <a
        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a
      >
    </p>

    <p>
      If you are located in Switzerland, the contact details for the data
      protection authorities are available here:
      <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"
        >https://www.edoeb.admin.ch/edoeb/en/home.html.</a
      >
    </p>
    <p>
      <span class="text-black"><a href="#">Withdrawing your consent:</a></span>
      If we are relying on your consent to process your personal information,
      which may be express and/or implied consent depending on the applicable
      law, you have the right to withdraw your consent at any time. You can
      withdraw your consent at any time by contacting us by using the contact
      details provided in the section
      <span class="text-black"
        ><a href="#">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a></span
      >
      below or updating your preferences.
    </p>
    <p>
      However, please note that this will not affect the lawfulness of the
      processing before its withdrawal nor, when applicable law allows, will it
      affect the processing of your personal information conducted in reliance
      on lawful processing grounds other than consent.
    </p>
    <p>
      <span class="text-black"
        ><a href="#"
          >Opting out of marketing and promotional communications:</a
        ></span
      >
      You can unsubscribe from our marketing and promotional communications at
      any time by clicking on the unsubscribe link in the emails that we send,
      replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by
      contacting us using the details provided in the section
      <a href="#"> "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a> below. You
      will then be removed from the marketing lists. However, we may still
      communicate with you — for example, to send you service-related messages
      that are necessary for the administration and use of your account, to
      respond to service requests, or for other non-marketing purposes.
    </p>

    <h4>Account Information</h4>
    <p>
      If you would at any time like to review or change the information in your
      account or terminate your account, you can:
    </p>
    <ul class="list-square px-5">
      <li>Log in to your account settings and update your user account.</li>
      <li>Contact us using the contact information provided.</li>
    </ul>

    <p>
      Upon your request to terminate your account, we will deactivate or delete
      your account and information from our active databases. However, we may
      retain some information in our files to prevent fraud, troubleshoot
      problems, assist with any investigations, enforce our legal terms and/or
      comply with applicable legal requirements.
    </p>
    <p>
      <span class="text-lack"
        ><a href="#">Cookies and similar technologies:</a></span
      >
      Most Web browsers are set to accept cookies by default. If you prefer, you
      can usually choose to set your browser to remove cookies and to reject
      cookies. If you choose to remove cookies or reject cookies, this could
      affect certain features or services of our Services. To opt out of
      interest-based advertising by advertisers on our Services visit
      <a href="#">http://www.aboutads.info/choices/.</a>
    </p>

    <p>
      If you have questions or comments about your privacy rights, you may email
      us at info@oonpay.com.
    </p>
    <h4>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ("DNT") feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for recognizing and implementing DNT signals
      has been finalized. As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice
      not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>

    <h4>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
    <p>
      <span class="text-black"> In Short:</span> Yes, if you are a resident of
      California, you are granted specific rights regarding access to your
      personal information.
    </p>

    <p>
      California Civil Code Section 1798.83, also known as the "Shine The Light"
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>

    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems (e.g.,
      backups, etc.).
    </p>

    <h4>CCPA Privacy Notice</h4>
    <p>The California Code of Regulations defines a "resident" as:</p>
    <ul>
      <li>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and
      </li>
      <li>
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
      </li>
    </ul>
    <p>All other individuals are defined as "non-residents."</p>
    <p>
      If this definition of "resident" applies to you, we must adhere to certain
      rights and obligations regarding your personal information.
    </p>
    <h6>What categories of personal information do we collect?</h6>
    <p>
      We have collected the following categories of personal information in the
      past twelve (12) months:
    </p>
    <!-- Table Here -->

    <table class="table table-bordered p-5">
      <thead>
        <tr>
          <th scope="col">Category</th>
          <th scope="col">Examples</th>
          <th scope="col">Collected</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">A. Identifiers</th>
          <td>
            Contact details, such as real name, alias, postal address, telephone
            or mobile contact number, unique personal identifier, online
            identifier, Internet Protocol address, email address, and account
            name
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">
            B. Personal information categories listed in the California Customer
            Records statute
          </th>
          <td>
            Name, contact information, education, employment, employment
            history, and financial information
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">
            C. Protected classification characteristics under California or
            federal law
          </th>
          <td>Gender and date of birth</td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">D. Commercial information</th>
          <td>
            Transaction information, purchase history, financial details, and
            payment information
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">E. Biometric information</th>
          <td>
            Fingerprints and voiceprints
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">F. Internet or other similar network activity</th>
          <td>
            Browsing history, search history, online behavior, interest data,
            and interactions with our and other websites, applications, systems,
            and advertisements
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">G. Geolocation data</th>
          <td>
            Device location
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information
          </th>
          <td>
            Images and audio, video or call recordings created in connection
            with our business activities
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">I. Professional or employment-related information</th>
          <td>
            Business contact details in order to provide you our Services at a
            business level or job title, work history, and professional
            qualifications if you apply for a job with us
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">J. Education Information</th>
          <td>
            Student records and directory information
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <th scope="row">
            K. Inferences drawn from other personal information
          </th>
          <td>
            Inferences drawn from any of the collected personal information
            listed above to create a profile or summary about, for example, an
            individual’s preferences and characteristics
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <th scope="row">
           L. Sensitive Personal Information
          </th>
          <td>
           
          </td>
          <td>NO</td>
        </tr>
      </tbody>
    </table>
    <p>
      We will use and retain the collected personal information as needed to
      provide the Services or for:
    </p>

    <ul class="list-square px-5">
      <li>Category K - As long as the user has an account with us</li>
    </ul>
    <p>
      We may also collect other personal information outside of these categories
      through instances where you interact with us in person, online, or by
      phone or mail in the context of:
    </p>
    <ul class="list-square px-5">
      <li>Receiving help through our customer support channels;</li>
      <li>Participation in customer surveys or contests; and</li>
      <li>
        Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </li>
    </ul>
    <h4>How do we use and share your personal information?</h4>
    <p>
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </p>
    <p>
      You may contact us by email at info@oonpay.com, or by referring to the
      contact details at the bottom of this document.
    </p>
    <p>
      If you are using an authorized agent to exercise your right to opt out we
      may deny a request if the authorized agent does not submit proof that they
      have been validly authorized to act on your behalf.
    </p>
    <h4>Will your information be shared with anyone else?</h4>
    <p>
      We may disclose your personal information with our service providers
      pursuant to a written contract between us and each service provider. Each
      service provider is a for-profit entity that processes the information on
      our behalf, following the same strict privacy protection obligations
      mandated by the CCPA.
    </p>
    <p>
      We may use your personal information for our own business purposes, such
      as for undertaking internal research for technological development and
      demonstration. This is not considered to be "selling" of your personal
      information.
    </p>
    <p>
      OONPAY INC has not disclosed, sold, or shared any personal information to
      third parties for a business or commercial purpose in the preceding twelve
      (12) months. OONPAY INC will not sell or share personal information in the
      future belonging to website visitors, users, and other consumers.
    </p>
    <h4>Your rights with respect to your personal data</h4>
    <a href="#">Right to request deletion of the data — Request to delete</a>
    <p>
      You can ask for the deletion of your personal information. If you ask us
      to delete your personal information, we will respect your request and
      delete your personal information, subject to certain exceptions provided
      by law, such as (but not limited to) the exercise by another consumer of
      his or her right to free speech, our compliance requirements resulting
      from a legal obligation, or any processing that may be required to protect
      against illegal activities.
    </p>
    <h4>Right to be informed — Request to know</h4>
    <ul class="list-square px-4">
      <li>whether we collect and use your personal information;</li>
      <li>the categories of personal information that we collect;</li>
      <li>
        the purposes for which the collected personal information is used;
      </li>
      <li>whether we sell or share personal information to third parties;</li>
      <li>
        the categories of personal information that we sold, shared, or
        disclosed for a business purpose;
      </li>
      <li>
        the categories of third parties to whom the personal information was
        sold, shared, or disclosed for a business purpose;
      </li>
      <li>
        the business or commercial purpose for collecting, selling, or sharing
        personal information; and
      </li>
      <li>
        the specific pieces of personal information we collected about you.
      </li>
    </ul>

    <p>
      In accordance with applicable law, we are not obligated to provide or
      delete consumer information that is de-identified in response to a
      consumer request or to re-identify individual data to verify a consumer
      request.
    </p>
    <a href="#"
      >Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
      Rights</a
    >
    <p>
      We will not discriminate against you if you exercise your privacy rights.
    </p>
    <a href="#"
      >Right to Limit Use and Disclosure of Sensitive Personal Information</a
    >
    <p>We do not process consumer's sensitive personal information.</p>
    <a href="#">Verification process</a>
    <p>
      Upon receiving your request, we will need to verify your identity to
      determine you are the same person about whom we have the information in
      our system. These verification efforts require us to ask you to provide
      information so that we can match it with information you have previously
      provided us. For instance, depending on the type of request you submit, we
      may ask you to provide certain information so that we can match the
      information you provide with the information we already have on file, or
      we may contact you through a communication method (e.g., phone or email)
      that you have previously provided to us. We may also use other
      verification methods as the circumstances dictate.
    </p>
    <p>
      We will only use personal information provided in your request to verify
      your identity or authority to make the request. To the extent possible, we
      will avoid requesting additional information from you for the purposes of
      verification. However, if we cannot verify your identity from the
      information already maintained by us, we may request that you provide
      additional information for the purposes of verifying your identity and for
      security or fraud-prevention purposes. We will delete such additionally
      provided information as soon as we finish verifying you.
    </p>
    <a href="#">Other privacy rights</a>
    <ul class="list-square px-5">
      <li>You may object to the processing of your personal information.</li>
      <li>
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the information
      </li>
      <li>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </li>
      <li>
        You may request to opt out from future selling or sharing of your
        personal information to third parties. Upon receiving an opt-out
        request, we will act upon the request as soon as feasibly possible, but
        no later than fifteen (15) days from the date of the request submission.
      </li>
    </ul>
    <p>
      To exercise these rights, you can contact us by email at info@oonpay.com,
      or by referring to the contact details at the bottom of this document. If
      you have a complaint about how we handle your data, we would like to hear
      from you.
    </p>
    <h4>13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
    <p>
      <span class="text-black">In Short: </span> Yes, if you are a resident of
      Virginia, you may be granted specific rights regarding access to and use
      of your personal information.
    </p>
    <h4>Virginia CDPA Privacy Notice</h4>
    <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
    <p>
      "Consumer" means a natural person who is a resident of the Commonwealth
      acting only in an individual or household context. It does not include a
      natural person acting in a commercial or employment context.
    </p>
    <p>
      "Personal data" means any information that is linked or reasonably
      linkable to an identified or identifiable natural person. "Personal data"
      does not include de-identified data or publicly available information.
    </p>
    <p>
      "Sale of personal data" means the exchange of personal data for monetary
      consideration.
    </p>
    <p>
      If this definition "consumer" applies to you, we must adhere to certain
      rights and obligations regarding your personal data.
    </p>
    <p>
      The information we collect, use, and disclose about you will vary
      depending on how you interact with OONPAY INC and our Services. To find
      out more, please visit the following links:
    </p>
    <ul class="style-square px-5">
      <li>Personal data we collect</li>
      <li>How we use your personal data</li>
      <li>When and with whom we share your personal data</li>
    </ul>
    <a href="#">Your rights with respect to your personal data</a>
    <ul class="style-square px-5">
      <li>
        Right to be informed whether or not we are processing your personal data
      </li>
      <li>Right to access your personal data</li>
      <li>Right to correct inaccuracies in your personal data</li>
      <li>Right to request deletion of your personal data</li>
      <li>
        Right to obtain a copy of the personal data you previously shared with
        us
      </li>
      <li>
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects ("profiling")
      </li>
    </ul>
    <p>
      OONPAY INC has not sold any personal data to third parties for business or
      commercial purposes. OONPAY INC will not sell personal data in the future
      belonging to website visitors, users, and other consumers.
    </p>
    <a href="#">Exercise your rights provided under the Virginia CDPA</a>
    <p>
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </p>
    <p>
      You may contact us by email at info@oonpay.com, by visiting our data
      subject request form, or by referring to the contact details at the bottom
      of this document.
    </p>
    <p>
      If you are using an authorized agent to exercise your rights, we may deny
      a request if the authorized agent does not submit proof that they have
      been validly authorized to act on your behalf.
    </p>
    <a href="#">Verification process</a>
    <p>
      We may request that you provide additional information reasonably
      necessary to verify you and your consumer's request. If you submit the
      request through an authorized agent, we may need to collect additional
      information to verify your identity before processing your request.
    </p>
    <p>
      Upon receiving your request, we will respond without undue delay, but in
      all cases, within forty-five (45) days of receipt. The response period may
      be extended once by forty-five (45) additional days when reasonably
      necessary. We will inform you of any such extension within the initial
      45-day response period, together with the reason for the extension.
    </p>
    <a href="#">Right to appeal</a>
    <p>
      If we decline to take action regarding your request, we will inform you of
      our decision and reasoning behind it. If you wish to appeal our decision,
      please email us at info@oonpay.com. Within sixty (60) days of receipt of
      an appeal, we will inform you in writing of any action taken or not taken
      in response to the appeal, including a written explanation of the reasons
      for the decisions. If your appeal if denied, you may contact the Attorney
      General to submit a complaint.
    </p>
    <h4>14. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
    <p>
      <span class="text-black">In Short:</span> Yes, we will update this notice
      as necessary to stay compliant with relevant laws.
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated "Revised" date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy notice, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy notice frequently to be informed of
      how we are protecting your information.
    </p>

    <h4>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
    <p>
      If you have questions or comments about this notice, you may email us at
      info@oonpay.com or by post to:
    </p>
    <pre>
      OONPAY INC
      10650 Culebra Rd
      STE 104 479
      San Antonio, TX 78254
      United States
    </pre>
    <h4>
      16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </h4>
    <p>
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it. To request to review, update, or delete
      your personal information, please submit a request form by clicking here.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
