<template>
  <div>
    <the-app-bar />
    <privacy/>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import Privacy from '../../components/MainView/privacy.vue';
import TheAppBar from "../../components/NavBar/TheAppBar.vue";

export default {
  components: { TheAppBar, Footer, Privacy },
};
</script>

<style scoped></style>
