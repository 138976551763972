<template>
  <nav class="navbar navbar-expand-lg py-10 mb-4 navbar-light bg-white">
    <div class="container">
      <!-- <a class="navbar-brand" href="#">Your Logo</a> -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item ">
            <router-link class="nav-link mr-4 " to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link mr-4" to="/about-us"
              >About us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link mr-4" to="#">Services</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link mr-4" to="/contact-us"
              >Contact us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link mr-4" to="/help-center"
              >Help Center</router-link
            >
          </li>
          <li v-if="islogin" class="nav-item mr-4 dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ account_info.firstName + " " + account_info.lastName }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/account/update-profile"
                >Edit Profile</router-link
              >
              <router-link class="dropdown-item" to="/account/reset-password"
                >Reset Password</router-link
              >
              <a href="/remove-account" class="dropdown-item btn "
                >Request Account Removal</a
              >
              <a class="dropdown-item" @click="logout" href="#">Log Out</a>
            </div>
          </li>

          <li v-else class="nav-item mr-4 dropdown">
            <router-link class="nav-link btn--grey" to="/account/client/login"
              >Login</router-link
            >
            <!-- <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Login
            </a> -->
            <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/account/client/login"
                >Client Login</router-link
              >

              <router-link class="dropdown-item" to="/account/merchant/login"
                >Merchant Login</router-link
              >
            </div> -->
          </li>

          <!-- <li v-if="!islogin" class="nav-item ">
            <router-link to="/join-network" class="nav-link btn--grey" href="#">
              Join The Network
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      islogin: localStorage.getItem("login_status"),
      account_info: JSON.parse(localStorage.getItem("account_info"))
    };
  },
  computed: {
    ...mapGetters("default", ["account"])
  },
  methods: {
    logout() {
      localStorage.clear();
      location.href = "/";
    }
  }
};
</script>

<style scoped>
@media (min-width: 900px) {
  .btn--grey {
    padding: 5px 30px;
    background: #f15825;
    color: white !important;
    border-radius: 20px;
    margin-left: 10px;
  }
  .navbar-nav {
    margin: 0 auto;
    display: table;
    table-layout: fixed;
  }
  .nav-item {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    margin: 0 10px;
  }
}
</style>
